/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import axios, { AxiosRequestConfig } from 'axios';

export type RequestType = 'get' | 'post' | 'patch' | 'put' | 'delete';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Try to load our token when the page opens
let authHeaders: {
    'X-Team-Id': string;
    'X-Auth-Token': string;
} | null = null;
try {
    authHeaders = JSON.parse(localStorage.getItem('authHeaders') as string);
}
catch (err) {
    console.error('Failed to load auth headers!');
    console.error(err);
}

/** Sets the latest long-term token and teamId for requests to the Wynn API */
export function setAuthHeaders(token: string, teamId: string, asrToken: string) {
    authHeaders = {
        'X-Auth-Token': token,
        'X-Team-Id': teamId,
    };
    localStorage.setItem('authHeaders', JSON.stringify(authHeaders));
    localStorage.setItem('asrToken', asrToken);
}

/** Returns if we have the headers stored locally. This doesn't necessarily mean the tokens are valid, though. */
export function hasAuthHeaders() {
    return (authHeaders !== null && localStorage.getItem('asrToken') !== null);
}

/** Removes the auth headers from local storage, effectively logging us out */
export function removeAuthHeaders() {
    authHeaders = null;
    localStorage.removeItem('authHeaders');
    localStorage.removeItem('asrToken');
}

/** Makes a request to the Wynn API */
export async function request<R = any>(endpoint: string, method: RequestType = 'get', data?: any, config?: AxiosRequestConfig<R>): Promise<R> {

    // Here is where would check if we're in test mode, and if so, mock out our API responses
    // I don't think it's necessary for an app as minimal as this - it'd be overkill, at least at this point.

    // So just conduct a regular API request
    try {
        const r = await axios.request<R>({
            url: endpoint,
            headers: authHeaders ?? {},
            ...config,
            method,
            data,
        });

        return r.data;
    }
    catch (err: any) {
        if (err.response) {
            if (err.response.status === 401) {
                
                // We might not be logged in, or our token is bad - either way, remove it and refresh to log in again
                removeAuthHeaders();
                window.location.href = `${window.location.protocol}//${window.location.host}/`;

            } else {
                if (err.response.data?.message) {
                    console.error(`API error: ${err.response.data?.message}`);
                }
            }
        }
        throw err;
    }
}