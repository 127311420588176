/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './DeliveryCard.module.css';
import Tappable from '../Tappable/index';

/** An option for different types of deliveries */
export default function DeliveryCard(props: {

    /** What this option is a delivery of, e.g. "food order" or "package" */
    type: string;

    /** Source string to use for the icon on the left of the button */
    icon: string;

    /** Source strings to use for each icon inside the button */
    minorIcons: string[];

    /** Callback function for when this option is clicked or tapped */
    onClick: () => void;

}) {
    return (
        <Tappable onClick={props.onClick} className={css.wrapper}>
            <img src={props.icon} className={css.icon} alt="Icon" draggable={false}/>
            <div className={css.content}>
                <h3 className={css.title}>
                    I'm delivering a <strong>{props.type}</strong>.
                </h3>
                <div className={css.brands}>
                    {props.minorIcons.map((icon, index) => (
                        <img key={index} src={icon} alt="Brand" className={css.brand} draggable={false}/>
                    ))}
                </div>
            </div>
        </Tappable>
    );
}