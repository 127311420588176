/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Centered from 'components/Centered';
import PrimaryButton from 'components/PrimaryButton';

/** Displays an error message to the user, and optionally provides a callback function to reset it */
export default function ErrorPage(props: {

    /** The error to display */
    error: Error;

    /** Optional callback to dismiss the error. If not provided, the reset button is not present. */
    onReset?: () => void;

}) {
    const { error, onReset } = props;
    return (
        <div style={{height: '100vh'}}>
            <Centered>
                <h1>Uh oh, we have a problem!</h1>
                <p>Open your browser console for more information.</p>
                <code>{ error.message }</code>
                { onReset && <PrimaryButton onClick={onReset}>Reset</PrimaryButton> }
            </Centered>
        </div>
    );
}