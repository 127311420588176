/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './WaitingIcon.module.css';

export default function WaitingIcon() {
    return (
        <div className={css.wrapper}>
            <div className={css.track}/>
            <div className={css.loader}/>
        </div>
    );
}