/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { request } from 'api';
import Centered from 'components/Centered';
import MeetingCard from 'components/MeetingCard';
import Scrollable from 'components/Scrollable';
import WaitingIcon from 'components/WaitingIcon';
import { useEffect } from 'react';
import Meeting from 'types/Meeting';
import Room from 'types/Room';
import css from './MeetingsPane.module.css';

/** Pane that displays upcoming meetings */
export default function MeetingsPane(props: {

    /** A list of available meetings */
    meetings: Meeting[] | null;

    /** Callback function to update the meeting list */
    setMeetings: (meetings: Meeting[] | null) => void;

    /** Callback function for when a meeting is selected */
    onCheckIn: (meeting: Meeting) => void;

    /** A list of all locations in the Office */
    rooms: Room[];

}) {

    const { meetings, setMeetings, rooms, onCheckIn } = props;

    // Load our meetings when this pane opens
    useEffect(() => {
        const abort = new AbortController();
        request<Meeting[]>('/meetings', 'get', {}, {
            signal: abort.signal,
        }).then((r) => {
            setMeetings(r);
        }).catch((err) => {
            if (err.message !== 'canceled') {
                console.error('Failed to load upcoming meetings!');
                console.error(err);
            }
        });
        return () => abort.abort();
    }, [ setMeetings ]);

    return (
        <>
            {/* If the meetings aren't loaded yet, show a WaitingIcon */}
            { meetings !== null ? <>
                { meetings.length === 0 ? <Centered>

                    {/* Helper text when no meetings found */}
                    <h3 className={css.noMeetings}>There are no on-site meetings scheduled for today.</h3>

                </Centered> : <Scrollable>

                    {/* Actually render our meeting cards */}
                    { meetings.sort((a, b) => {
                        return new Date(a.start_time) > new Date(b.start_time) ? 1 : -1;
                    }).map((meeting, index) => <MeetingCard
                        name={meeting.name}
                        time={new Date(meeting.start_time)}
                        location={rooms.find((r) => r.location === meeting.location)?.name ?? meeting.location ?? ''}
                        attendees={meeting.attendees}
                        onClick={() => onCheckIn(meeting)}
                        key={index}
                    />)}

                </Scrollable> }
            </> : <Centered>
                <WaitingIcon/>
            </Centered> }
        </>
    );
}