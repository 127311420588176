/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './PrimaryButton.module.css'

export default function PrimaryButton(props: {
    
    children: string

    onClick?: () => void;

}): JSX.Element {
    return (
        <button className={css.wrapper} onClick={props.onClick}>
            { props.children }
        </button>
    )
}