/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { useErrorBoundary } from 'react-use-error-boundary';
import ErrorPage from 'pages/ErrorPage';
import LoginWrapper from 'components/LoginWrapper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CallPage from 'pages/CallPage';
import MainPage from 'pages/MainPage';
import MediaWrapper from 'components/MediaWrapper';
import { useState } from 'react';
import TestPage from 'pages/TestPage';

export default function App() {

    const [ mediaStream, setMediaStream ] = useState<MediaStream | null>(null);

    // Handle caught application errors
    const [ error, resetError ] = useErrorBoundary((err, info) => {
        console.error('Encountered an error!');
        console.error(err);
        console.error(info);
    });
    if (error) {
        return (
            <ErrorPage error={error} onReset={resetError}/>
        );
    }

    return (
        <MediaWrapper 
            mediaStream={mediaStream}
            setMediaStream={setMediaStream}
        >
            <BrowserRouter>
                <Routes>
                    <Route path='/call' element={
                        <CallPage/>
                    }/>
                    <Route path='/test' element={
                        <LoginWrapper>
                            <TestPage mediaStream={mediaStream!}/>
                        </LoginWrapper>
                    }/>
                    <Route index element={
                        <LoginWrapper>
                            <MainPage mediaStream={mediaStream!}/>
                        </LoginWrapper>
                    }/>
                </Routes>
            </BrowserRouter>
        </MediaWrapper>
    );
}