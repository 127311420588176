/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import TextSection from 'components/TextSection';
import css from './Centered.module.css';

/** Centers the contained elements on the screen and styles all h1 and p elements within them */
export default function Centered(props: {

    children: React.ReactNode;

}) {
    return (
        <div className={css.centered}>
            <TextSection>
                { props.children }
            </TextSection>
        </div>
    );
}