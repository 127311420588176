/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import DeliveryCard from 'components/DeliveryCard';
import css from './DeliveriesPane.module.css'

import food from 'images/actions/food.svg';
import packageicon from 'images/actions/package.svg';
import amazon from 'images/brands/amazon.svg';
import fedex from 'images/brands/fedex.svg';
import dhl from 'images/brands/dhl.svg';
import ups from 'images/brands/ups.svg';
import usps from 'images/brands/usps.svg';
import doordash from 'images/brands/doordash.svg';
import postmates from 'images/brands/postmates.svg';
import ubereats from 'images/brands/ubereats.svg';
import grubhub from 'images/brands/grubhub.svg';
import Centered from 'components/Centered';
import WaitingIcon from 'components/WaitingIcon';

/** Pane that shows different delivery options for the visitor */
export default function DeliveriesPane(props: {

    onDelivery: (room: string | 'LEAVE HERE') => void;

    deliveries: {
        food: string;
        packages: string;
    } | null;

}) {
    const { onDelivery, deliveries } = props;

    return (
        <>
            { deliveries !== null ? <ul className={css.wrapper}>
                <DeliveryCard 
                    type='food order' 
                    icon={food} 
                    minorIcons={[doordash, postmates, ubereats, grubhub]} 
                    onClick={() => onDelivery(deliveries.food)}  
                />
                <DeliveryCard 
                    type='package' 
                    icon={packageicon} 
                    minorIcons={[dhl, usps, ups, fedex, amazon]} 
                    onClick={() => onDelivery(deliveries.packages)}
                />
            </ul> : <Centered>
                <WaitingIcon/>
            </Centered> }
        </>
    );
}