/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import css from './Scrollable.module.css';

export default function Scrollable(props: {

    children: React.ReactNode;

}) {

    const ref = useRef<HTMLUListElement | null>(null);
    const scrollTarget = useRef<number | null>(null);
    const [ scroll, setScroll ] = useState(0);
    const [ showUpArrow, setShowUpArrow ] = useState(false);
    const [ showDownArrow, setShowDownArrow ] = useState(false);

    useEffect(() => {
        const scroller = ref.current;
        if (scroller) {
            scroller.scrollTop = scroll;

            // Determine if we should show our arrows or not
            if (scroll > 0) {
                setShowUpArrow(true);
            } else {
                setShowUpArrow(false);
            }

            if (scroll < scroller.scrollHeight - scroller.clientHeight) {
                setShowDownArrow(true);
            } else {
                setShowDownArrow(false);
            }
        }
    }, [ scroll, ref.current?.scrollHeight ]);

    const scrollBy = (scrollOffset: number) => {
        const scroller = ref.current;
        if (scroller) {
            const val = Math.max(0, Math.min(scroller.scrollTop + scrollOffset, scroller.scrollHeight - scroller.clientHeight));
            setScroll(val);
            scrollTarget.current = val;
        }
    }

    const upArrowClasses = classNames({
        [css.arrow]: true,
        [css.arrowUp]: true,
        [css.visible]: showUpArrow,
    });

    const downArrowClasses = classNames({
        [css.arrow]: true,
        [css.arrowDown]: true,
        [css.visible]: showDownArrow,
    });

    return (
        <div className={css.wrapper}>
            <IconChevronUp className={upArrowClasses} size={80} color='var(--color-chevron)' onClick={showUpArrow ? () => scrollBy(-600) : undefined}/>
            <ul className={css.scrollable} onScroll={(ev) => {
                // If we reach the top or bottom, hide our scroll arrows
                // And if we aren't at the top or bottom, show the arrows
                const val = (ev.target as HTMLUListElement);
                if (val.scrollTop <= 0) {
                    setShowUpArrow(false);
                } else {
                    setShowUpArrow(true);
                }
                const scroller = ref.current;
                if (scroller) {
                    if (val.scrollTop >= scroller.scrollHeight - scroller.clientHeight) {
                        setShowDownArrow(false);
                    } else {
                        setShowDownArrow(true);
                    }
                }
            }} ref={ref}>
                { props.children }
            </ul>
            <IconChevronDown className={downArrowClasses} size={80} color='var(--color-chevron' onClick={showDownArrow ? () => scrollBy(600) : undefined}/>
        </div>
    );
}