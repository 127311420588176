/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import PersonCard from 'components/PersonCard';
import Room from 'types/Room';
import roomIcon from 'images/room.png';

export default function RoomCard(props: {

    room: Room;

    onDirections: () => void;

}) {
    const { room, onDirections } = props;
    return (
        <PersonCard
            name={room.name}
            title={room.type}
            thumbnail={roomIcon}
            onNotify={onDirections}
            notifyLabel='Directions'
        />
    );
}