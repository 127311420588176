/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { format } from 'date-fns';

import React, { useCallback, useEffect, useState } from 'react';
import useRefState from 'hooks/useRefState';

import { request } from 'api';

import BackButton from 'components/BackButton';
import Centered from 'components/Centered';
import Listening from 'components/Listening';
import ControlPane from 'components/panes/ControlPane';
import DeliveriesPane from 'components/panes/DeliveriesPane';
import DirectoryPane from 'components/panes/DirectoryPane';
import MeetingsPane from 'components/panes/MeetingsPane';
import WelcomePane from 'components/panes/WelcomePane';
import WaitingIcon from 'components/WaitingIcon';

import { SpeechProcessor, SpeechProcessorOptions } from 'speech/SpeechProcessor';
import { TranscriptionEvent } from 'speech/Transcription';
import { FaceDetector } from 'face/FaceDetector';

import Contact from 'types/Contact';
import Meeting from 'types/Meeting';
import Room from 'types/Room';
import SpeechIntent from 'types/SpeechIntent';

import getIntentPrompts from 'util/getIntentPrompts';
import retry from 'util/retry';
import takePicture from 'util/takePicture';

import css from './MainPage.module.css';

export type MainPageStatus = 'welcome' | 'meeting' | 'delivery' | 'directory' | 'goto' | 'text' | 'loading';

/** Main landing page for the receptionist */
export default function MainPage(props: {

    /** Stream from the device's webcam */
    mediaStream: MediaStream;

}) {

    const { mediaStream } = props;

    // Determines what panes to display
    const [ status, setStatus ] = useState<MainPageStatus>('welcome');

    // When status is 'goto', this determines the text/room
    const [ gotoData, setGotoData ] = useState<{
        label: string;
        location: string;
        imageUrl?: string;
    } | null>(null);

    // When status is 'text', render this data in the center of the screen
    const [ textData, setTextData ] = useState<{
        heading: string;
        children: React.ReactNode;
    } | null>(null);

    // Set up ref for when we need to load meetings
    const [ meetings, setMeetings ] = useRefState<Meeting[] | null>(null);

    // Get our rooms, contacts, delivery options, and speech processor options
    const [ rooms, setRooms ] = useState<Room[] | null>(null);
    const [ contacts, setContacts ] = useState<Contact[] | null>(null);
    const [ deliveries, setDeliveries ] = useState<{
        food: string;
        packages: string;
    } | null>(null);
    const [ speechProcessorOptions, setSpeechProcessorOptions ] = useState<SpeechProcessorOptions | null>(null);
    useEffect(() => {
        const abort = new AbortController();
        request<Room[]>('/rooms', 'get', {}, {
            signal: abort.signal,
        }).then((r) => {
            setRooms(r);
        }).catch((err) => {
            if (err.message !== 'canceled') {
                console.error('Failed to load Rooms!');
                console.error(err);
            }
        });
        request<Contact[]>('/contacts', 'get', {}, {
            signal: abort.signal,
        }).then((c) => {
            setContacts(c);
        }).catch((err) => {
            if (err.message !== 'canceled') {
                console.error('Failed to load Contacts!');
                console.error(err);
            }
        });
        request('/deliveries', 'get', {}, {
            signal: abort.signal,
        }).then((d) => {
            setDeliveries(d);
        }).catch((err) => {
            if (err.message !== 'canceled') {
                console.error('Failed to load delivery options!');
                console.error(err);
            } 
        });
        request<SpeechProcessorOptions>('/config', 'get', {}, {
            signal: abort.signal,
        }).then((opts) => {
            setSpeechProcessorOptions(opts);
        }).catch((err) => {
            if (err.message !== 'canceled') {
                console.error('Failed to load SpeechProcessorOptions!');
                console.error(err);
            }
        });
        return () => abort.abort();
    }, []);


    // Called when a visitor checks in to a meeting
    const onCheckIn = useCallback(async (meeting: Meeting) => {
        if (status !== 'loading') {
            try {
                setStatus('loading');

                const photo = await takePicture(mediaStream);
                const response = await request<{
                    message: string;
                    room: Room;
                }>(`/meetings/checkin`, 'post', {
                    name: meeting.name,
                    location: meeting.location,
                    start_time: meeting.start_time,
                    attendees: meeting.attendees,
                    photo,
                });
                
                // If the start is more than 15 minutes away, show the "you're here early!" screen
                const d = new Date(meeting.start_time);
                if (d.valueOf() - Date.now() > 15*60*1000) {
                    setStatus('text');
                    setTextData({
                        heading: "You're here early!",
                        children: (
                            <>
                                <p>Your meeting doesn't start until</p>
                                <h3 className={css.startTime}>{ format(d, "h:mm a") }</h3>
                                <p>Please take a seat and wait here until your meeting. The meeting attendees have been notified and will come get you soon.</p>
                            </>
                        ),
                    })
                } else {
                    // Otherwise, show directions to the room
                    setStatus('goto');
                    setGotoData({
                        label: 'Please head to:',
                        location: response.room.name,
                        imageUrl: response.room.directions,
                    });
                }
            }
            catch (err) {
                setStatus('text');
                setTextData({
                    heading: 'Whoops! Something went wrong.',
                    children: (<p>Feel free to walk in and ask for help. Sorry about the inconvenience!</p>),
                });
                console.error('Failed to check in for a meeting!');
                console.error(err);
            }
        }
    },  [ mediaStream, status ]);

    // Called when a visitor is making a delivery
    const onDelivery = useCallback(async (room: string | 'LEAVE HERE') => {
        if (room === 'LEAVE HERE') {
            if (status !== 'loading') {
                // Set message to leave the delivery here
                setStatus('loading');
                try {
                    // API notification
                    const photo = await takePicture(mediaStream);
                    await request('/deliveries', 'post', {
                        photo,
                    });
                }
                catch (err) {
                    console.error('Failed to send delivery notification!');
                    console.error(err);
                }
                setStatus('text');
                setTextData({
                    heading: 'You can leave it right here!',
                    children: (<>
                        <p>Thank you very much for the delivery. We've notified someone to come get it from here, but you can leave it.</p>
                        <p>We appreciate your service!</p>
                    </>),
                });
            }
        } else {
            // Set directions for the room we're going to
            if (!rooms) {
                throw new Error('No rooms are defined for this office!');
            } else {
                const r = rooms.find((o) => o.location === room);
                if (!r) {
                    throw new Error(`A room with location "${room}" does not exist in this office.`);
                }
                setStatus('goto');
                setGotoData({
                    label: 'Please drop the order in:',
                    location: r.name,
                    imageUrl: r.directions,
                });
            }
        }
    }, [ mediaStream, rooms, status ]);

    // Notify a contact
    const onNotify = useCallback(async (contact: Contact) => {
        if (status !== 'loading') {
            try {
                setStatus('loading');

                // Make our API request with the photo
                const photo = await takePicture(mediaStream);
                await request(`/contacts/${contact._id}/notify`, 'post', {
                    photo,
                });

                // Change our state
                setStatus('text');
                setTextData({
                    heading: 'Just a moment...',
                    children: (<p><strong style={{color: 'black'}}>{ contact.name.split(' ')[0] }</strong> has been notified and will come get you shortly. Thank you for waiting!</p>),
                });
            }
            catch (err) {
                setStatus('text');
                setTextData({
                    heading: 'Whoops! Something went wrong.',
                    children: (<p>Feel free to walk in and ask for help. Sorry about the inconvenience!</p>),
                });
                console.error(`Failed to notify contact: ${contact.name}`);
                console.error(err);
            }
        }
    }, [ mediaStream, status ]);

    // Start a video call with a contact
    const onCall = (contact: Contact) => {
        // TODO
    };

    // Takes the visitor to a specific room
    const onDirections = (room: Room) => {
        setStatus('goto');
        setGotoData({
            label: 'Please head to:',
            location: room.name,
            imageUrl: room.directions,
        });
    }

    // Calls for help for the user
    const onHelp = useCallback(async () => {
        if (status !== 'loading') {
            try {
                setStatus('loading');
                const photo = await takePicture(mediaStream);
                await request('/help', 'post', {
                    photo,
                });
                setStatus('text');
                setTextData({
                    heading: 'Just a moment...',
                    children: (<p>Someone will be out front to help you out shortly. Thank you for waiting!</p>),
                });
            }
            catch (err) {
                setStatus('text');
                setTextData({
                    heading: 'Whoops! Something went wrong.',
                    children: (<p>Feel free to walk in and ask for help. Sorry about the inconvenience!</p>),
                });
                console.error('Failed to request help!');
                console.error(err);
            }
        }
    }, [ mediaStream, status ]);

    // Attempts to determine intent from the provided transcript, and calls the appropriate callback
    const [ isListening, setIsListening ] = useState(false);
    const [ hidePane, setHidePane ] = useRefState(false); // When true, renders a loading icon instead of content in our side panes. Set when processing a transcript from the welcome pane, and is true during the second call of this method.
    const [ misunderstood, setMisunderstood ] = useState(false); // When true, renders the blue "I couldn't understand that" bubble
    const [ noFace, setNoFace ] = useState(false); // When true, renders the blue "Click on an option" bubble
    const onTranscriptReceived = useCallback(async (currentStatus: MainPageStatus, e: TranscriptionEvent) => {
        setIsListening(true);
        try {
            if (contacts && rooms && deliveries) {
                console.log('================');
                console.log('Incoming transcript: ', e.transcript);

                const requestBody = {
                    transcript: e.transcript,
                    duration: e.duration,
                    ...getIntentPrompts(currentStatus, contacts, meetings.current ?? [], rooms),
                };

                const result = await request<SpeechIntent>('/speech', 'post', requestBody);
                console.log('Recognized intent: ', result.intent, result.confidence);

                // Upload the audio file asynchronously, but only if it's the first time this audio has been heard
                if (!hidePane.current) { // This is true when we're doing a second-pass
                    retry(() => fetch(result.upload, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'audio/wav',
                        },
                        body: e.wav,
                    }), 1000, 10, (err, attempt) => {
                        console.error(`Upload attempt ${attempt} failed!`);
                        console.error(err);
                    }).then(() => {
                        console.log('Uploaded transcript audio successfully.');
                    }).catch((err) => {
                        console.error('Failed to upload audio from transcript!')
                        console.error(err);
                    });
                }

                // Call callbacks depending on what the result was
                if (result.intent !== 'unknown') {
                    switch (currentStatus) {
                        case 'welcome': {
                            switch (result.intent) {
                                case 'meeting': {
                                    setStatus('meeting');
                                    setHidePane(true);
                                    // Get our current meetings in our ref before we call this function again
                                    const m = await request<Meeting[]>('/meetings');
                                    setMeetings(m);
                                    onTranscriptReceived('meeting', e);
                                    break;
                                }
                                case 'delivery': {
                                    setStatus('delivery');
                                    setHidePane(true);
                                    onTranscriptReceived('delivery', e);
                                    break;
                                }
                                case 'directory': {
                                    setStatus('directory');
                                    setHidePane(true);
                                    onTranscriptReceived('directory', e);
                                    break;
                                }
                                case 'help': {
                                    onHelp();
                                    break;
                                }
                                default: break;
                            }
                            break;
                        }
                        case 'meeting': {
                            // Check in to this meeting
                            if (meetings.current && requestBody.options) {
                                const meeting = (requestBody.options as Meeting[]).find(m => m.id === result.intent);
                                if (meeting)
                                    onCheckIn(meeting);
                            }
                            setHidePane(false);
                            break;
                        }
                        case 'delivery': {
                            switch (result.intent) {
                                case 'food': {
                                    onDelivery(deliveries.food);
                                    break;
                                }
                                case 'package': {
                                    onDelivery(deliveries.packages);
                                    break;
                                }
                                default: break;
                            }
                            setHidePane(false);
                            break;
                        }
                        case 'directory': {
                            // Notify the person with the corresponding intent
                            const idx = requestBody.options?.indexOf(result.intent) ?? -1;
                            if (idx >= 0) {
                                onNotify(contacts[idx]);
                                setHidePane(false);
                            }
                            break;
                        }
                        default: break;
                    }
                } else {
                    // Unrecognized intent
                    if (!hidePane.current) {
                        // If this wasn't from a hidden pane, show unrecognized bubble
                        setMisunderstood(false); // Needed to reset the animation
                        setMisunderstood(true);
                    }
                    setHidePane(false);
                }
            }
        }
        catch (err) {
            console.error('Error handling transcript!');
            console.error(err);
        }
        setIsListening(false);
    }, [ contacts, deliveries, rooms, onCheckIn, onDelivery, onHelp, onNotify, meetings, setMeetings, hidePane, setHidePane ]);

    // Set up our speech processor and face detector
    const [ speechProcessor, setSpeechProcessor ] = useState<SpeechProcessor | null>(null);
    const [ faceDetector, setFaceDetector ] = useState<FaceDetector | null>(null);

    useEffect(() => {
        if (!speechProcessorOptions) return;
        const asrToken = localStorage.getItem('asrToken');
        if (!asrToken) throw new Error('No asrToken found! Try logging in again.');

        // Speech processor
        const sp = new SpeechProcessor(asrToken, {
            stream: mediaStream,
            ...speechProcessorOptions,
        });
        sp.start();
        setSpeechProcessor(sp);

        // Face detector
        const fd = new FaceDetector({ loop_interval_ms: 500 });
        fd.load();
        // Don't start the face detector loop - only detect faces before we send an audio file to transcribe
        setFaceDetector(fd);

        // Set the should transcribe check function to see if there's a face
        sp.setShouldTranscribeFunction(async () => {
            // If we detect at least one face, then let's transcribe
            const faces = await fd.detect();
            console.log("Detected", faces?.length, "face(s)...");
            if (faces?.length) return true;
            
            // If a user is actually here and we just can't see their face, ask them to click on an option
            setNoFace(false);
            setNoFace(true);

            return false;
        });

        // Clean up
        return () => {
            sp.stop();
            fd.stop();
        }
    }, [ mediaStream, speechProcessorOptions ]);

    // Add/remove our event from the SpeechProcessor
    // This is separate so that we can update the handler as our state or status changes without reinitializing the entire SpeechProcessor
    useEffect(() => {
        if (!speechProcessor) return;
        const t = (e: TranscriptionEvent) => onTranscriptReceived(status, e);
        speechProcessor.addEventListener('transcript', t);
        return () => {
            speechProcessor.removeEventListener('transcript', t);
        }
    }, [ status, speechProcessor, onTranscriptReceived ]);

    // Whenever our status changes:
    // - Set the enabled flag of the SpeechProcessor appropriately
    // - Start a timeout to reset to the main screen (when not in development mode)
    useEffect(() => {
        if (speechProcessor) {
            speechProcessor.enabled = ['welcome', 'meeting', 'delivery', 'directory'].includes(status);
        }
        if (status !== 'welcome' && process.env.NODE_ENV !== 'development') {
            const t = setTimeout(() => {
                setStatus('welcome');
                setTextData(null);
                setGotoData(null);
            }, 2*60*1000); // 2-minutes before we reset the screen
            return () => clearTimeout(t);
        }
    }, [ status, speechProcessor ]);

    return (
        <main>
            {/* Before our rooms have loaded, or if we are in a loading status, render a waiting icon */}
            { rooms === null || contacts === null || deliveries === null || status === 'loading' || speechProcessor === null || faceDetector === null ? 
                <Centered>
                    <WaitingIcon/>
                </Centered>
            :
                <>
                    {/* Render back button if we aren't at the welcome screen */}
                    { status !== 'welcome' && <BackButton
                        onClick={() => {
                            setStatus('welcome');
                            setGotoData(null);
                            setTextData(null);
                        }}
                    /> }

                    {/* If we are in text status, show our data as centered text */}
                    { status === 'text' && textData !== null ? 
                        <Centered>
                            <h1>{ textData.heading }</h1>
                            { textData.children }
                        </Centered>
                    :
                        <>
                            {/* We are in a different status, so render the left/right panes */}
                            <div className={css.left}>
                                <ControlPane
                                    title={(() => {
                                        switch (status) {
                                            case 'welcome': return 'How can we help you?';
                                            case 'meeting': return 'Check-in to your meeting.';
                                            case 'delivery': return 'What are you delivering?';
                                            case 'directory': return 'Who are you looking for?';
                                            default: return '';
                                        }
                                    })()}
                                    text={(() => {
                                        switch (status) {
                                            case 'welcome': return 'Select an option. You can also say what you want or who you\'re looking for.';
                                            case 'meeting': return 'Select an option. You can also say the name of a meeting or who you\'re meeting with.';
                                            case 'delivery': return 'Select an option or say what you\'re delivering for instructions.';
                                            case 'directory': return 'Click on a person or say "Notify" followed by the name of who you\'re looking for.';
                                            default: return '';
                                        }
                                    })()}
                                    // Only supply our room data if we are in the right status for it
                                    goToRoom={ status === 'goto' && gotoData !== null ? {
                                        label: gotoData.label,
                                        location: gotoData.location,
                                    } : undefined}
                                >
                                    {/* Render the Listening component within the Pane */}
                                    {/* I moved this here from ControlPane so we wouldn't have to drill all these props in */}
                                    { ['welcome', 'meeting', 'delivery', 'directory'].includes(status) && <Listening
                                        sp={speechProcessor}
                                        fd={faceDetector}
                                        isLoading={isListening}
                                        misunderstood={misunderstood}
                                        setMisunderstood={setMisunderstood}
                                        noFace={noFace}
                                        setNoFace={setNoFace}
                                    />}
                                </ControlPane>
                            </div>
                            <div className={css.right}>
                                {(() => {
                                    switch (status) {
                                        case 'welcome': return (<WelcomePane
                                            onMeeting={() => {
                                                setStatus('meeting');
                                                setMeetings(null);
                                            }}
                                            onDelivery={() => {
                                                setStatus('delivery');
                                            }}
                                            onDirectory={() => {
                                                setStatus('directory');
                                            }}
                                            onHelp={onHelp}
                                        />);
                                        case 'meeting': return (
                                            <MeetingsPane 
                                                meetings={hidePane.current ? null : meetings.current}
                                                setMeetings={setMeetings}
                                                onCheckIn={onCheckIn}
                                                rooms={rooms}
                                            />);
                                        case 'delivery': return (
                                            <DeliveriesPane
                                                deliveries={hidePane.current ? null : deliveries}
                                                onDelivery={onDelivery}
                                            />);
                                        case 'directory': return (
                                            <DirectoryPane
                                                contacts={hidePane.current ? null : contacts}
                                                onNotify={onNotify}
                                                onCall={onCall}
                                                rooms={rooms}
                                                onDirections={onDirections}
                                            />);
                                        case 'goto': return (<img className={css.directions} src={gotoData?.imageUrl} alt='' draggable={false}/>);
                                        default: throw new Error(`Unexpected MainPage status! ${status}`);
                                    }
                                })()}
                            </div>
                        </>
                    }
                </>
            }
        </main>
    );
}