/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './Clock.module.css';
import { useState, useEffect } from 'react';
import format from 'date-fns/format';

/** A clock that renders the current time, weekday, month, date, and year */
export default function Clock() {

    const [ time, setTime ] = useState<Date>(new Date())

    useEffect(() => {
        const timeInterval = setInterval(() => setTime(new Date()), 20000); // Every 20 seconds means we stay accurate with 20 seconds
        return () => clearInterval(timeInterval);
    }, [time]);

    return (
        <div className={css.wrapper}>
            <time className={css.clock}>{format(time, "h:mm a")}</time>
            <time className={css.date}>{format(time, "EEEE, MMMM d, yyyy")}</time>
        </div>
    );
}