/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import ActionSquare from 'components/ActionSquare';
import css from './WelcomePane.module.css';
import meetingSrc from 'images/actions/meeting.png';
import deliverySrc from 'images/actions/delivery.png';
import directorySrc from 'images/actions/directory.png';
import helpSrc from 'images/actions/help.png';

/** Main pane that shows the four main options for a visitor */
export default function WelcomePane(props: {

    /** Callback function when the visitor has a meeting */
    onMeeting: () => void;

    /** Callback function when the visitor is delivering something */
    onDelivery: () => void;

    /** Callback function when the visitor is looking for someone */
    onDirectory: () => void;

    /** Callback function when somebody asks for help */
    onHelp: () => void;

}) {
    const { onMeeting, onDelivery, onDirectory, onHelp } = props;
    return (
        <div className={css.welcomePane}>
            <ActionSquare
                label={<>I'm here for a <strong>meeting</strong>.</>}
                imageSrc={meetingSrc}
                onClick={onMeeting}
                className={css.topLeft}
            />
            <ActionSquare
                label={<>I'm delivering <strong>food</strong> or a <strong>package</strong>.</>}
                imageSrc={deliverySrc}
                onClick={onDelivery}
                className={css.topRight}
            />
            <ActionSquare
                label={<>I'm looking for <strong>someone</strong>.</>}
                imageSrc={directorySrc}
                onClick={onDirectory}
                className={css.bottomLeft}
            />
            <ActionSquare
                label={<>Something else...</>}
                imageSrc={helpSrc}
                onClick={onHelp}
                className={css.bottomRight}
            />
        </div>
    );
}