/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import blobToBase64 from 'util/blobToBase64';

/** Takes a picture of the provided MediaStream, and returns it as a base64-encoded string */
export default async function takePicture(stream: MediaStream): Promise<string> {
    const track = stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(track);
    const blob = await imageCapture.takePhoto();
    return await blobToBase64(blob);
}