/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './TextSection.module.css';

/** Formats contained all h1 and p elements consistently */
export default function TextSection(props: {

    children: React.ReactNode;

}) {
    return (
        <section className={css.textSection}>
            { props.children }
        </section>
    );
}