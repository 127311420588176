/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Clock from 'components/Clock';
import FullscreenButton from 'components/FullscreenButton';
import GoToRoom from 'components/GoToRoom';
import TextSection from 'components/TextSection';
import css from './ControlPane.module.css';

/** The left-side pane that controls the user and listens to audio input */
export default function ControlPane(props: {

    /** The title to show at the top of this pane */
    title: string;

    /** Informative directional text for the user */
    text: string;

    /** If provided, the default title/text combination is replaced with a GoToRoom component */
    goToRoom?: {
        label: string;
        location: string;
    }

    /** Elements to place in the center of the pane - should typically be a Listening component */
    children: React.ReactNode;

}) {
    const { title, text, goToRoom, children } = props;

    return (
        <div className={css.controlPane}>
            <FullscreenButton/>
            { !goToRoom ?
                <TextSection>
                    <h1>{ title }</h1>
                    <p>{ text }</p>
                </TextSection>
            :
                <GoToRoom label={goToRoom.label} location={goToRoom.location}/>
            }
            { children }
            <Clock/>
        </div>
    );
}