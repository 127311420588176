/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { MainPageStatus } from 'pages/MainPage';
import Contact from 'types/Contact';
import Meeting from 'types/Meeting';
import Room from 'types/Room';

export default function getIntentPrompts(status: MainPageStatus, contacts: Contact[], meetings: Meeting[], rooms: Room[]): {
    prompt: string;
    options?: (string | Meeting)[];
} | null {
    switch (status) {
        case 'welcome': return { prompt: 'welcome' };
        case 'delivery': return { prompt: 'delivery' };
        case 'directory': return {
            prompt: 'directory',
            options: contacts.map((c) => c.title ? `${c.name} | ${c.title}` : c.name),
        };
        case 'meeting': return {
            prompt: 'meeting',
            options: meetings
        };
        default: return null;
    }
}