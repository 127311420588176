/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Tappable from 'components/Tappable';
import Attendee from 'types/Attendee';
import { format } from 'date-fns'
import css from './MeetingCard.module.css';

/** Card for a meeting, listing the time, name, place, and attendees. */
export default function MeetingCard(props: {

    /** The time this meeting is */
    time: Date;

    /** The name of the meeting this card is for */
    name: string;

    /** The location where this meeting is - corresponds to a room in the office */
    location: string;

    /** A list of everyone who is attending this meeting */
    attendees: Attendee[];

    /** Callback function when this meeting card is clicked/tapped */
    onClick: () => void;

}) {

    return (
        <Tappable onClick={props.onClick} className={css.wrapper}>
            <time className={css.time}>
                {format(props.time, 'h:mm')}
            </time>
            <div className={css.content}>
                <h3 className={css.name}>{props.name}</h3>
                <h4 className={css.location}>{props.location}</h4>
                <ul className={css.attendees}>
                    {props.attendees.slice(0,5).map((attendee) => (
                        <li key={attendee.email} className={css.attendee}>
                            <div className={css.placeholder}>
                                <p>{ attendee.email.charAt(0).toUpperCase() }</p>
                                { attendee.user?.avatar_url &&  <img src={attendee.user.avatar_url} className={css.thumbnail} alt="Thumbnail" draggable={false}/> }
                            </div>
                            <p className={css.attendeename}>{attendee.user?.name || attendee.email}</p>
                        </li>
                    ))}
                    {props.attendees.length > 5 && <li className={css.attendee}>...</li>}
                </ul>
            </div>
        </Tappable>
    );
}