/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { hasAuthHeaders, request, setAuthHeaders } from 'api';
import Centered from 'components/Centered';
import WaitingIcon from 'components/WaitingIcon';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import sleep from 'util/sleep';

/** Wrapper that ensures the children are not rendered if the user isn't logged in */
export default function LoginWrapper(props: {

    /** What to render when the user is logged in */
    children: React.ReactNode;

}): JSX.Element {
    
    const { children } = props;
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ error, setError ] = useState<string | null>(null);

    useEffect(() => {

        // Do we have a long-term token stored?
        if (!hasAuthHeaders()) {

            // Do we have a coforio token in our page URL?
            const cioToken = searchParams.get('code');
            const error = searchParams.get('error');
            if (error) {
                throw new Error(`Coforio error: ${error}`);
            }
            
            if (!cioToken) {
                // We don't have a coforio token yet. Go get one.
                sleep(800).then(() => {
                    window.location.href = `https://cofor.io/oauth/login?product=${process.env.REACT_APP_COFORIO_PRODUCT_ID}&redirect_uri=${window.location.protocol}//${window.location.host}`;
                });
            } else {
                // We have a coforio token - attempt our POST /auth to get our long-term token
                const abort = new AbortController();
                sleep(500).then(async () => {
                    const response = await request<{
                        token: string;
                        teamId: string;
                        asr_token: string;
                    }>('/auth', 'post', {}, {
                        headers: {
                            'Authorization': `Bearer ${cioToken}`,
                        },
                        signal: abort.signal,
                    });
                    setAuthHeaders(response.token, response.teamId, response.asr_token);
                    setLoggedIn(true);
                    searchParams.delete('code');
                    searchParams.delete('error');
                    setSearchParams(searchParams);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.error(err);
                        setError('Something went wrong! Try refreshing?');
                    }
                });
                return () => abort.abort();
            }
        } else {
            // We already have our long-term token. Continue.
            setLoggedIn(true);
        }
    }, [ searchParams, setSearchParams ]);

    if (loggedIn) {
        return (
            <>
                { children }
            </>
        );
    } else {
        return (
            <div style={{height: '100vh'}}>
                <Centered>
                    { error ? <> 
                        <h1>Oops!</h1>
                        <p>{ error }</p>
                    </> : <>
                        <WaitingIcon/>
                        <p style={{paddingTop: '40px'}}>Loading...</p>
                    </> }
                </Centered>
            </div>
        );
    }
}