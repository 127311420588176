/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Centered from 'components/Centered';
import PersonCard from 'components/PersonCard';
import RoomCard from 'components/RoomCard';
import Scrollable from 'components/Scrollable';
import WaitingIcon from 'components/WaitingIcon';
import Contact from 'types/Contact';
import Room from 'types/Room';
import css from './DirectoryPane.module.css';

/** Pane that lists team members for the visitor to notify or call upon their arrival */
export default function DirectoryPane(props: {

    /** All people who should show up in this list */
    contacts: Contact[] | null;

    /** Callback function for when the visitor notifies a team member */
    onNotify: (contact: Contact) => void;
    
    /** Callback function for when the visitor tries to call a team member */
    onCall?: (contact: Contact) => void;
    
    /** Rooms to show up as options in the directory */
    rooms: Room[];

    /** Callback function for when the visitor wants directions to a specific room */
    onDirections: (room: Room) => void;

}) {

    const { contacts, onNotify, onCall, rooms, onDirections } = props;
    
    return (
        <>
            { contacts !== null ? <>
                {/* If contacts aren't loaded, show a WaitingIcon */}
                { contacts.length === 0 ? <Centered>

                    {/* Text for when there's no contacts */}
                    <h3 className={css.noContacts}>Huh, there's nobody here... Spooky.</h3>

                </Centered> : <Scrollable>
                
                    {/* Render PersonCards */}
                    { contacts.map((contact, index) => <PersonCard 
                        name={contact.name}
                        title={contact.title}
                        thumbnail={contact.avatar_url}
                        onNotify={() => onNotify(contact)}
                        onCall={onCall && (() => onCall(contact))}
                        key={index}
                    />)}

                    {/* Render RoomCards */}
                    { rooms.map((room, index) => <RoomCard
                        room={room}
                        onDirections={() => onDirections(room)}
                        key={contacts.length + index}
                    />)}

                </Scrollable> }
            </> : <Centered>
                <WaitingIcon/>
            </Centered> }
        </>
    );
}