/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { useEffect, useState } from 'react';
import css from './FullscreenButton.module.css';
import fullscreenIcon from 'images/fullscreen.png';

/** Button that toggles the full-screen status of the page */
export default function FullscreenButton(props: {

    /** If true, button will transform into a minimize button when the page is already fullscreen. If false, the button won't render. Defaults to false. */
    renderWhenFullscreen?: boolean;

}) {

    const [ isFullScreen, setIsFullScreen ] = useState(document.fullscreenElement !== null);

    useEffect(() => {
        const onScreenChange = () => {
            if (document.fullscreenElement !== null) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        }
        document.addEventListener('fullscreenchange', onScreenChange);
        return () => document.removeEventListener('fullscreenchange', onScreenChange);
    }, []);

    if (isFullScreen && !props.renderWhenFullscreen) return (<></>);

    return (
        <button className={css.wrapper} onClick={async () => {
            if (!isFullScreen) {
                await document.documentElement.requestFullscreen();
                setIsFullScreen(true);
            } else {
                await document.exitFullscreen();
                setIsFullScreen(false);
            }
        }}>
            <img src={fullscreenIcon} alt='Fullscreen'/>
            <p>Go to full-screen mode</p>
        </button>
    );
}