/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { IconArrowLeft } from '@tabler/icons';
import css from './BackButton.module.css';

/** Back button that appears in the top-left corner of different pages */
export default function BackButton(props: {onClick: () => void}) {
    return (
        <button className={css.wrapper} onClick={props.onClick}>
            <IconArrowLeft className={css.icon} strokeWidth={2} />
        </button>
    );
}