/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Centered from 'components/Centered';
import WaitingIcon from 'components/WaitingIcon';
import { useEffect, useState } from 'react';

/** Calls getUserMedia and won't render the children until media is accepted */
export default function MediaWrapper(props: {

    /** Children not to display until the MediaStream is set */
    children: React.ReactNode;

    /** The current MediaStream */
    mediaStream: MediaStream | null;

    /** Callback function to set the MediaStream */
    setMediaStream: (mediaStream: MediaStream) => void;


}) {
    const { children, mediaStream, setMediaStream } = props;
    const [ error, setError ] = useState<Error | null>(null);

    useEffect(() => {
        if (!mediaStream) {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: {
                    sampleRate: 48000,
                },
            }).then((stream) => {
                // Success!
                setMediaStream(stream);
            }).catch((err) => {
                console.error('Failed to stream user media!');

                // Replace some error texts with more user-friendly text
                // This is applicable to Chrome - not sure about other browsers
                const text = err.message?.toLowerCase();
                if (text === 'requested device not found') {
                    setError(new Error('Please connect a webcam and microphone to use Wynn.'));
                } else if (text === 'permission denied') {
                    setError(new Error('You denied permission to use your webcam and/or microphone. Please reset permissions and try again.'));
                } else {
                    setError(err);
                }
            });
        }

        // Stop our streaming tracks on clean-up
        return () => {
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        }
    }, [ mediaStream, setMediaStream ]);

    // If media failed, let the error be caught by our error boundary
    if (error) {
        throw error;
    }

    if (mediaStream === null) {
        return (
            <div style={{height: '100vh'}}>
                <Centered>
                    <h1>Please connect your media.</h1>
                    <p>Wynn needs access to both a camera and a microphone.</p>
                    <p>Having trouble? Try refreshing.</p>
                    <WaitingIcon/>
                </Centered>
            </div>
        );
    } else {
        return (
            <>
                { children }
            </>
        );
    }
}