/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import PrimaryButton from 'components/PrimaryButton';
import css from './PersonCard.module.css';

/** A card for one person in the company directory, with a notify option */
export default function PersonCard(props: {

    /** The name of this person */
    name: string;

    /** The title of this person in the company, e.g. CEO, Architect, Developer, etc. */
    title?: string;

    /** The profile picture to display for this person */
    thumbnail?: string;

    /** Callback function for when the notify button for this card is clicked or tapped */
    onNotify: () => void;

    /** Callback function for when the call button is clicked or tapped */
    onCall?: () => void;

    /** If provided, will override the default "Notify" button text */
    notifyLabel?: string;

}) {
    return (
        <div className={css.wrapper}>
            <div className={css.person}>
                <div className={css.placeholder}>
                    <p>{props.name.charAt(0).toUpperCase()}</p>
                    { props.thumbnail && <img src={props.thumbnail} className={css.thumbnail} alt={props.name} draggable={false}/> }
                </div>
                <div className={css.content}>
                    <h3>
                        {props.name}
                    </h3>
                    <h4>
                        {props.title}
                    </h4>
                </div>
            </div>
            <PrimaryButton onClick={props.onNotify}>{ props.notifyLabel ?? 'Notify' }</PrimaryButton>
        </div>
    );
}