/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './Tappable.module.css';
import classNames from 'classnames';

/** A wrapper that provides consistent styling across all major screen buttons */
export default function Tappable(props: {

    /** Contents to render within the button */
    children: React.ReactNode;

    /** Callback function for when this button is clicked or tapped */
    onClick?: () => void;

    /** Optionally classes you can apply to the button */
    className?: string;

}) {
    const classes = classNames({
        [css.wrapper]: true,
        [props.className ?? '']: true,
    });
    return (
        <button className={classes} onClick={props.onClick}>
            { props.children }
        </button>
    );
}