/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

/** Page that renders for a team member when they are called from the receptionist */
export default function CallPage() {

    return (
        <>
            call page
        </>
    );
}