/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import sleep from 'util/sleep';

/** 
 * Will repeatedly attempt the callback function.
 * 
 * The callback function must return a Promise. If the Promise rejects, the callback will be attempted again.
 * When the Promise resolves, this function's returned promise will then resolve.
 * 
 * You can provide an attemptDelay, which will delay the next attempt. Set to 0 to retry right away.
 * 
 * If you define maxAttempts, the function will give up if the Promise doesn't resolve after that many attempts.
 * If this happens, this throws an Error or rejects, depending on if you're using it as a Promise or async/await.
 * Set this to 0 to never stop retrying.
 * 
 * You can also provide an onRejected callback that is called each time the Promise rejects, with the current attempt number.
 */
export default async function retry<T>(fn: () => Promise<T>, attemptDelay = 5000, maxAttempts = 0, onRejected?: (err: unknown, attempt: number) => void): Promise<T> {
    let success = false;
    let attempts = 1;
    let result: T | null = null;

    while (!success)
        {
        try {
            result = await fn();
            success = true;
        }
        catch (err) {
            // Increment our attempts
            if (onRejected) {
                onRejected(err, attempts);
            }
            attempts++;
            if (maxAttempts > 0 && attempts > maxAttempts) {
                throw new Error(`Max attempts (${maxAttempts}) exceeded.`);
            }
            
            // If we have a delay, wait that long to try again
            if (attemptDelay > 0) {
                await sleep(attemptDelay);
            }
        }
    }

    return result!;
}