/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import React, { useCallback, useRef, useState } from 'react';

/** 
 * Stores your state as a ref. This ensures that, as long as you use the ref, it won't become stale immediately after calling the update callback. 
 * 
 * This is very useful if you have some kind of recursive callback function and need to store a value both as a state - because you
 * want to cause re-renders when it updates, and as a ref - because you want the "pointer" to the most recent value.
 * 
 * @example
 * ```typescript
 * const [ value, setValue ] = useRefState(2);
 * 
 * useEffect(() => {
 *     value.current; // 2
 *     setValue(6);
 *     value.current; // 6 - would have stayed as 2 if this was just a regular useState call
 * }, [])
 * ```
*/
export default function useRefState<T = any>(defaultValue: T): [ React.MutableRefObject<T>, (newValue: T) => void ] {

    const setState = useState<T>(defaultValue)[1];
    const ref = useRef<T>(defaultValue);

    const update = useCallback((newValue: T) => {
        setState(newValue);
        ref.current = newValue;
    }, [ setState ]);

    return [ ref, update ];
}