/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Tappable from 'components/Tappable';
import css from './ActionSquare.module.css';

/** A welcome page square that shows a new visitor an available option */
export default function ActionSquare(props: {

    /** Source to use for the icon on this square */
    imageSrc: string;

    /** Elements to use as the label */
    label: React.ReactNode;
    
    /** Callback function for when this square is clicked or tapped */
    onClick: () => void;

    /** Optional classes you can apply to the button */
    className?: string;

}) {
    return (
        <Tappable onClick={props.onClick} className={props.className}>
            <div className={css.wrapper}>
                <img src={props.imageSrc} className={css.image} alt="Action" draggable={false}/>
                <p className={css.label}>{props.label}</p>
            </div>
        </Tappable>
    );
}