/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { FaceDetection, loadTinyFaceDetectorModel, tinyFaceDetector, TinyFaceDetectorOptions } from 'face-api.js';
// import { FaceDetection, loadSsdMobilenetv1Model, detectAllFaces } from 'face-api.js';

import * as tf from '@tensorflow/tfjs';

/** Event type for when we detected a face */
export class FaceDetectedEvent extends Event {
    constructor() {
        super('face_on');
    }
}

/** Event type for when a face disappeared */
export class FaceDisappearedEvent extends Event {
    constructor() {
        super('face_off');
    }
}

/** Event type for live face data */
export class FaceDataEvent extends Event {
    faces: FaceDetection[];
    constructor(faces: FaceDetection[]) {
        super('face_data');
        this.faces = faces;
    }
}

/** Event type for when the face detector encouters an error */
export class FaceDetectorError extends Event {
    readonly error: any;
    constructor(error: any) {
        super('error');
        this.error = error;
    }
}

/** FaceDetector class constructor options */
export class FaceDetectorOptions {
    stream?: MediaStream
    loop_interval_ms?: number
    inactive_after_ms?: number
}

/** Face Detector class */
export class FaceDetector extends EventTarget {
    loop_interval_ms: number
    inactive_after_ms: number
    stream?: MediaStream
    webcam?: any
    
    // video: HTMLVideoElement
    active: boolean
    private inView: boolean
    private ready: boolean

    private loop?: NodeJS.Timer
    private inactive?: NodeJS.Timeout

    constructor(opts?: FaceDetectorOptions) {
        super();
        this.stream = opts?.stream;
        this.loop_interval_ms = opts?.loop_interval_ms ?? 1000;
        this.inactive_after_ms = opts?.inactive_after_ms ?? 3000;

        this.active = false;
        this.inView = false;
        this.ready = false;

        // Create hidden DOM video element for stream
        // this.video = document.createElement('video');
        // this.video.width = 640;
        // this.video.height = 360;
        // this.video.autoplay = true;
        // this.video.muted = true;
        // document.body.appendChild(this.video);
    }

    /** Load face detector models */
    load = async () => {
        // Set stream for video element
        this.webcam = await tf.data.webcam(undefined, {
            resizeWidth: 720,
            resizeHeight: 360
        });

        console.info("Loading face detector model...");

        // Load face detection model to memory
        await loadTinyFaceDetectorModel('/models/face-detector');
        // await loadSsdMobilenetv1Model('/models/face-detector');

        console.info("Loaded!");
        this.ready = true;
    }

    /** One-time detect call */
    detect = async () => {
        const capture = await this.webcam.capture();
        // const detections = await detectAllFaces(capture);
        return await tinyFaceDetector(capture, new TinyFaceDetectorOptions());
    }

    /** Start face detection loop */
    start = () => {
        if (this.loop) return;
        this.loop = setInterval(async () => {
            if (!this.ready || !this.webcam) return;
            
            const detections = await this.detect();

            this.inView = detections.length > 0;

            // Newly detected?
            if (this.inView && !this.active) {
                this.dispatchEvent(new FaceDetectedEvent());
                this.active = true;
            }

            // Newly disappeared?
            if (this.active && !this.inView) {
                this.inactive = setTimeout(() => {
                    this.dispatchEvent(new FaceDisappearedEvent());
                    this.inactive = undefined;
                    this.active = false;
                }, this.inactive_after_ms);
            }

            // Face data...
            if (this.inView) {
                this.clearInactiveTimeout();
                this.dispatchEvent(new FaceDataEvent(detections));
            }

        }, this.loop_interval_ms);
    }

    /** Stop face detection loop */
    stop = () => {
        if (this.loop) {
            clearInterval(this.loop);
            this.loop = undefined;
        }

        this.clearInactiveTimeout();
    }

    /** Event listener promotions to super */
    addEventListener = (type: 'face_on' | 'face_off' | 'face_data' | 'error', callback: (event: Event) => void) => {
        super.addEventListener(type, callback);
    }

    remoteEventListener = (type: 'face_on' | 'face_off' | 'face_data' | 'error', callback: (event: Event) => void) => {
        super.removeEventListener(type, callback);
    }

    dispatchEvent = (event: FaceDetectedEvent | FaceDisappearedEvent | FaceDetectorError) => {
        return super.dispatchEvent(event);
    }

    /** INTERNAL helpers */
    private clearInactiveTimeout = () => {
        if (!this.inactive) return;
        clearTimeout(this.inactive);
        this.inactive = undefined;
    }
}