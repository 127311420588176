/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

/** Converts a blob into a Base64 string */
export default async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (ev) => {
            if (reader.result && typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject('Blob conversion did not result in a string!');
            }
        });
        reader.addEventListener('error', (err) => {
            reject(reader.error);
        });
        reader.readAsDataURL(blob);
    });
}