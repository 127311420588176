/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import css from './GoToRoom.module.css';

/** Tells a user to go to a certain room in the office */
export default function GoToRoom(props: {

    /** Directional text depending on the context of why the user is going somewhere */
    label: string;

    /** The name of the room in the office the user should go to */
    location: string;

}) {
    return (
        <div className={css.wrapper}>
            <h2 className={css.label}>{props.label}</h2>
            <h1 className={css.location}>{props.location}</h1>
        </div>
    );
}