/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import Centered from "components/Centered";
import { useEffect, useState } from "react";
import { SpeechProcessor, VolumeEvent } from "speech/SpeechProcessor";
import { TranscriptionEvent } from "speech/Transcription";

/** Used to test user intent detection */
export default function TestPage(props: {

    mediaStream: MediaStream;

}) {

    const { mediaStream } = props;
    const [ text, setText ] = useState('Start saying something to test...');
    const [ volume, setVolume ] = useState(0);
    const [ smooth, setSmooth ] = useState(0);
    const [ lastTranscript, setLastTranscript ] = useState('');

    useEffect(() => {
        const asrToken = localStorage.getItem('asrToken');
        if (!asrToken) throw new Error('No asrToken found! Try logging in again.');

        const sp = new SpeechProcessor(asrToken, {
            stream: mediaStream,
        });

        sp.start();
        sp.addEventListener('volume', e => {
            const v = e as VolumeEvent;
            setVolume(v.volume);
            setSmooth(v.smooth);
        });
        sp.addEventListener('voice_on', (e) => {
            setText('Voice on...');
        });
        sp.addEventListener('voice_off', () => {
            setText('Voice off.');
        });
        sp.addEventListener('transcript', async (e) => {
            const t = e as TranscriptionEvent;
            setLastTranscript(t.transcript);
            // const result = await request<SpeechIntent>('/speech', 'post', {
            //     transcript: t.transcript,
            //     duration: t.duration,
            //     prompt: 'How can we help you?',
            //     options: [
            //         "I'm here for a meeting.",
            //         "I'm delivering food or a package.",
            //         "I'm looking for a person.",
            //         "I'm lost or I need help."
            //     ]
            // });
        });

        return () => sp.stop();
    }, [ mediaStream ]);

    return (
        <div style={{height: '100vh'}}>
            <Centered>
                <h1>{ text }</h1>
                <p>Last Transcript:</p>
                <code>{ lastTranscript }</code>
                <p style={{textAlign: 'left', width: '100%'}}>Volume: { Math.trunc(volume*10000)/10000 }</p>
                <p style={{textAlign: 'left', width: '100%'}}>Smoothed Volume: { Math.trunc(smooth*10000)/10000 }</p>
            </Centered>
        </div>
    );
}