/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

/** A FIFO is a buffer where you can add elements to it and it can be fetched in the same order */
export class FIFO<ElementType> {
    private elements: { [idx: number]: ElementType }
    private head: number
    private tail: number
    size: number

    constructor(size?: number) {
        this.elements = {};
        this.head = 0;
        this.tail = 0;
        this.size = size ?? 1024;
    }

    /** Add an element to the buffer */
    in = (x: ElementType) => {
        this.elements[this.tail] = x;
        this.tail++;

        // If we hit the buffer size, remove an element
        if (this.tail > this.head + this.size)
            this.pop();
    }

    /** Remove an element from the buffer */
    out = () => {
        const x = this.elements[this.head];
        this.pop();
        return x;
    }

    /** Get (and remove) all elements in the buffer */
    all = () => {
        let elements: ElementType[] = [];
        while (this.tail > this.head)
            elements.push(this.out());
        return elements;
    }

    /** See what element is at the end of the buffer without removing */
    peek = () => this.elements[this.head];

    /** Remove the element at the end of the buffer */
    pop = () => {
        delete this.elements[this.head];
        this.head++;
    }

    /** Clear buffer */
    clear = () => {
        this.elements = {};
        this.head = 0;
        this.tail = 0;
    }

    get length() {
        return this.tail - this.head;
    }

    get isEmpty() {
        return this.length === 0;
    }
}