/* Developed by Inventives, Inc. <https://inventives.ai> */
/* See LICENSE.md file in project root directory */

import { useCallback, useEffect, useRef, useState } from 'react';
import { SpeechProcessor, SpeechProcessorError, VolumeEvent } from 'speech/SpeechProcessor';
import css from './Listening.module.css';
import microphoneIcon from 'images/microphone.png';
import { FaceDetector } from 'face/FaceDetector';

/** Renders a microphone with a circular background, the changes size as the audio input fluctuates */
export default function Listening(props: {

    /** Needed as a prop to gain access to volume events dispatched by the processor */
    sp: SpeechProcessor;
    fd: FaceDetector

    /** Set to true to render the microphone icon's border as a spinning loading icon */
    isLoading: boolean;

    /** If we should render the "I couldn't understand that" bubble */
    misunderstood: boolean;

    /** Called when the misunderstanding bubble has animated away */
    setMisunderstood: (misunderstood: boolean) => void;

    /** If we should render the "Click on an option" bubble */
    noFace: boolean;

    /** Called when the noFace button has animated away */
    setNoFace: (noFace: boolean) => void;

}) {
    const { sp, fd, isLoading, misunderstood, setMisunderstood, noFace, setNoFace } = props;
    const canvas = useRef<(HTMLCanvasElement & { ctx?: CanvasRenderingContext2D | null}) | null>(null);
    const [ error, setError ] = useState<string | null>(null);
    const [ voiceOn, setVoiceOn ] = useState(false);
    const [ muted, setMuted ] = useState(false);
    
    // Add volume and error event listeners to the SpeechProcessor
    let radius = useRef<{
        actual: number;
        target: number;
        frame: number;
    }>({
        actual: 0,
        target: 0,
        frame: 0,
    });

    useEffect(() => {
        const volumeListener = (e: VolumeEvent) => {
            // Calculate our new target radius
            radius.current.target = e.volume / 0.1;
        }
        const errorListener = (e: SpeechProcessorError) => {
            setError(e.error.message ?? 'Something went wrong!');
        }
        const voiceOnListener = () => setVoiceOn(true);
        const voiceOffListener = () => setVoiceOn(false);
        sp.addEventListener('volume', volumeListener);
        sp.addEventListener('error', errorListener);
        sp.addEventListener('voice_on', voiceOnListener);
        sp.addEventListener('voice_off', voiceOffListener);
        return () => {
            // And remove the listeners on unmount
            sp.removeEventListener('volume', volumeListener);
            sp.removeEventListener('error', errorListener);
            sp.removeEventListener('voice_on', voiceOnListener);
            sp.removeEventListener('voice_off', voiceOffListener);
        };
    }, [ sp ]);

    useEffect(() => {
        fd.addEventListener('face_on', e => {
            sp.muted = false;
            setMuted(false);
        });

        fd.addEventListener('face_off', e => {
            sp.muted = true;
            radius.current.target = 0;
            setMuted(true);
        });
    }, [ fd, sp ]);

    const animate = useCallback(() => {
        const c = canvas.current;
        if (c?.ctx) {
            // Step our actual radius to our target radius
            if (Math.abs(radius.current.target - radius.current.actual) < 0.05) {
                // "Snap" to the value when sufficiently close
                // This probably won't happen much except for when there's no audio coming in
                radius.current.actual = radius.current.target;
            } else {
                // Otherwise, interpolate between the points (smaller values make the transition smoother from one radius to the next)
                radius.current.actual += 0.2 * (radius.current.target - radius.current.actual);
            }

            // Draw an ellipse with this actual radius
            c.ctx.clearRect(0, 0, 200, 200);
            c.ctx.beginPath();
            if (voiceOn) {
                c.ctx.fillStyle = '#60cfff';
            } else {
                c.ctx.fillStyle = '#d7d7d7';
            }
            const r = Math.max(50, Math.min(100, 50 + (radius.current.actual * 50))); // Put in terms of pixels between 50-100
            c.ctx.ellipse(c.width / 2, c.height / 2, r, r, 0, 0, 2 * Math.PI);
            c.ctx.fill();

            // Keep our current frame handle up to date so it can be canceled
            radius.current.frame = requestAnimationFrame(animate);
        }
    }, [ voiceOn ]);

    // Set up our animation frame when we render
    useEffect(() => {
        radius.current.frame = requestAnimationFrame(animate);

        // We want this to cancel with whatever the latest ref value is, ignore the warning
        // eslint-disable-next-line
        return () => cancelAnimationFrame(radius.current.frame);
    }, [ animate ])

    return (
        <>
            <div className={css.wrapper}>
                <canvas className={css.canvas} ref={(can) => {
                    if (can) {
                        // Initialize our drawing context
                        canvas.current = can;
                        if (!canvas.current.ctx) {
                            canvas.current.width = 200;
                            canvas.current.height = 200;
                            canvas.current.ctx = canvas.current.getContext('2d');
                            if (!canvas.current.ctx) {
                                console.warn('Failed to initialize 2D canvas context!');
                            } else {
                                canvas.current.ctx.fillStyle = 'var(--color-accent)';
                                canvas.current.ctx.globalAlpha = 0.2;
                            }
                        }
                    }
                }}/>
                <div className={css.listening} style={{borderColor: muted ? 'var(--color-muted)' : voiceOn ? 'var(--color-accent)' : '#d7d7d7'}}>
                    <img src={microphoneIcon} alt='Microphone' style={{opacity: voiceOn ? '1' : '0.75'}}/>
                </div>
                { isLoading && <div className={css.loading}/> }
                { misunderstood ?
                    <div className={css.speechBubble} onAnimationEnd={() => setMisunderstood(false)}>
                        <div className={css.speechBubbleArrow}/>
                        <p>I couldn't understand that...</p>
                    </div> : noFace ?
                    <div className={css.speechBubble} onAnimationEnd={() => setNoFace(false)}>
                        <div className={css.speechBubbleArrow}/>
                        <p>Please click on an option...</p>
                    </div> : ""
                }

            </div>
            { error && <p className={css.error}>{ error }</p> }
        </>
    );
}